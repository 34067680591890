import Vue from "vue";
import Vuex from "vuex";
import Admin from "./interfaces/Admin";
import Association from "./interfaces/Association";
import City from "./interfaces/City";
import { CouponPreview } from "./interfaces/Coupon";
import Postcode from "./interfaces/Postcode";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    admin: {
      isLogged: false,
      token: "",
      expires: 0,
      userId: 0,
    },
    alert: {
      message: "",
      color: "",
      show: false,
    },
    data: {
      config: {
        isActive: null as null | boolean,
        promoStartDate: null as null | string,
        promoEndDate: null as null | string,
        promoDueDate: null as null | string,
        dateYoungest: null as null | string,
        dateOldest: null as null | string,
      },
      admins: [] as Array<Admin>,
      associations: [] as Array<Association>,
      postcodes: [] as Array<Postcode>,
      coupons: null as null | Array<CouponPreview>,
      cities: [] as Array<City>,
    },
  },
  mutations: {
    ADMIN(state, payload) {
      state.admin = payload;
    },
    ADMINS(state, payload) {
      state.data.admins = [...payload];
    },
    ALERT(state, payload) {
      state.alert = { ...payload };
    },
    CONFIG(state, payload) {
      state.data.config = payload;
    },
    COUPONS(state, payload) {
      state.data.coupons = payload;
    },
    ASSOCIATIONS(state, payload) {
      state.data.associations = payload;
    },
    POSTCODES(state, payload) {
      state.data.postcodes = [...payload];
    },
    CITIES(state, payload) {
      state.data.cities = [...payload];
    },
  },
  actions: {
    showAlert(
      context,
      payload: { message: string; color: string; timeout: number },
    ) {
      context.commit("ALERT", {
        message: payload.message,
        color: payload.color,
        show: true,
      });
      setTimeout(() => {
        context.commit("ALERT", {
          message: payload.message,
          color: payload.color,
          show: false,
        });
        setTimeout(() => {
          context.commit("ALERT", { message: "", color: "", show: false });
        }, 500);
      }, payload.timeout);
    },
    async resetCampaign(context, payload) {
      try {
        const route = `${process.env.VUE_APP_API_HOST}/config/resetcampaign`;
        const headers = new Headers();
        headers.set("Authorization", `Bearer ${context.state.admin.token}`);
        headers.set("Content-Type", "application/json");

        const response = await fetch(route, {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        });
        if (response.status === 401) {
          context.dispatch("showAlert", {
            message: "Le mot de passe est incorrect",
            color: "warning",
            timeout: 4000,
          });
          return;
        }
        if (response.status === 200) {
          await this.dispatch("refreshCoupons");
          context.dispatch("showAlert", {
            message: "La campagne a été correctement réinitialisée",
            color: "success",
            timeout: 4000,
          });
        } else {
          throw "something";
        }
        return;
      } catch (error) {
        console.error(error);
        context.dispatch("showAlert", {
          message: "Impossible de récupérer les infos serveur pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async getCsvData(
      context,
      payload: { association_id?: number; city_id?: number },
    ) {
      try {
        const query = Object.keys(payload).length
          ? `?${Object.keys(payload)
              .map(
                (value) => `${value}=${payload[value as keyof typeof payload]}`,
              )
              .join("&")}`
          : "";
        const route = `${process.env.VUE_APP_API_HOST}/csv${query}`;
        const headers = new Headers();
        headers.set("Authorization", `Bearer ${context.state.admin.token}`);

        const response = await fetch(route, {
          headers,
        });

        if (!(response.status === 200)) {
          throw "error";
        }

        const blob = await response.blob();
        if (blob) {
          const a = document.createElement("a");
          a.style.display = "none";

          a.href = URL.createObjectURL(blob);
          a.download = `CSV-${
            new Date(Date.now()).toISOString().split("T")[0]
          }-quercycaussadais.csv`;
          a.click();
        }
      } catch (error) {
        console.error(error);
        context.dispatch("showAlert", {
          message: "Impossible de récupérer les infos serveur pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async refreshAdmins(context) {
      try {
        if (context.state.admin.isLogged && context.state.admin.token) {
          const route = `${process.env.VUE_APP_API_HOST}/admin`;
          const headers = new Headers();
          headers.set("Authorization", `Bearer ${context.state.admin.token}`);
          const response = await fetch(route, {
            headers,
          });
          if (response.status === 200) {
            context.commit("ADMINS", await response.json());
          } else {
            context.dispatch("showAlert", {
              message:
                "Impossible de récupérer les infos serveur pour le moment",
              color: "warning",
              timeout: 4000,
            });
          }
        }
      } catch (error) {
        context.dispatch("showAlert", {
          message: "Impossible de récupérer les infos serveur pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async addAdmin(context, payload) {
      try {
        const route = `${process.env.VUE_APP_API_HOST}/admin`;
        const headers = new Headers();
        headers.set("Authorization", `Bearer ${context.state.admin.token}`);
        headers.set("Content-Type", "application/json");
        const response = await fetch(route, {
          headers,
          method: "POST",
          body: JSON.stringify(payload),
        });
        if (response.status === 201) {
          context.dispatch("showAlert", {
            message: "Administrateur ajouté avec succès",
            color: "success",
            timeout: 3000,
          });
          await context.dispatch("refreshAdmins");
        } else {
          context.dispatch("showAlert", {
            message: "Impossible de récupérer les infos serveur pour le moment",
            color: "warning",
            timeout: 4000,
          });
        }
      } catch (error) {
        context.dispatch("showAlert", {
          message: "Impossible d'ajouter un administrateur actuellement",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async deleteAdmin(context, payload: number) {
      try {
        const route = `${process.env.VUE_APP_API_HOST}/admin/${payload}`;
        const headers = new Headers();
        headers.set("Authorization", `Bearer ${context.state.admin.token}`);

        const response = await fetch(route, {
          headers,
          method: "DELETE",
        });
        if (response.status === 200) {
          context.dispatch("showAlert", {
            message: "Administrateur supprimé avec succès",
            color: "success",
            timeout: 3000,
          });
          await context.dispatch("refreshAdmins");
        } else {
          context.dispatch("showAlert", {
            message: "Impossible de récupérer les infos serveur pour le moment",
            color: "warning",
            timeout: 4000,
          });
        }
      } catch (error) {
        context.dispatch("showAlert", {
          message: "Impossible de supprimer cet administrateur",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async refreshConfig(context) {
      if (context.state.admin.isLogged && context.state.admin.token) {
        try {
          const route = `${process.env.VUE_APP_API_HOST}/config`;
          const headers = new Headers();
          headers.set("Authorization", `Bearer ${context.state.admin.token}`);
          const response = await fetch(route, {
            headers,
          });
          if (response.status === 200) {
            context.commit("CONFIG", { ...(await response.json()) });
          } else {
            context.dispatch("showAlert", {
              message:
                "Impossible de récupérer les infos serveur pour le moment",
              color: "warning",
              timeout: 4000,
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    async updateConfig(context, payload): Promise<boolean> {
      try {
        if (context.state.admin.isLogged && context.state.admin.token) {
          const route = `${process.env.VUE_APP_API_HOST}/config`;
          const headers = new Headers();
          headers.set("Authorization", `Bearer ${context.state.admin.token}`);
          headers.set("Content-Type", "application/json");

          const response = await fetch(route, {
            headers,
            method: "PUT",
            body: JSON.stringify(payload),
          });
          if (response.status === 200) {
            context.commit("CONFIG", { ...payload });
            context.dispatch("showAlert", {
              message: "Configuration mise à jour avec succès",
              color: "success",
              timeout: 4000,
            });
            return true;
          } else {
            context.dispatch("showAlert", {
              message:
                "Impossible de mettre à jour la configuration actuellement",
              color: "warning",
              timeout: 4000,
            });
            return false;
          }
        } else {
          return false;
        }
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Le serveur n'est pas en mesure de traiter les demandes actuellement",
          color: "error",
          timeout: 3000,
        });
        return false;
      }
    },
    async refreshCoupons(context) {
      if (context.state.admin.isLogged && context.state.admin.token) {
        try {
          const route = `${process.env.VUE_APP_API_HOST}/coupon`;
          const headers = new Headers();
          headers.set("Authorization", `Bearer ${context.state.admin.token}`);
          const response = await fetch(route, {
            headers,
          });
          if (response.status === 200) {
            const year = new Date().getFullYear();
            const isoNewYear = `${year}-01-01T00:00:00.000Z`;
            const coupons: CouponPreview[] = await response.json();
            context.commit("COUPONS", [
              ...coupons.filter((coupon) => coupon.createdAt > isoNewYear),
            ]);
          } else {
            context.dispatch("showAlert", {
              message:
                "Impossible de récupérer les infos serveur pour le moment",
              color: "warning",
              timeout: 4000,
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    async refreshAssociations(context) {
      if (context.state.admin.isLogged && context.state.admin.token) {
        try {
          const route = `${process.env.VUE_APP_API_HOST}/association`;
          const response = await fetch(route);
          if (response.status === 200) {
            context.commit("ASSOCIATIONS", [...(await response.json())]);
          } else {
            context.dispatch("showAlert", {
              message:
                "Impossible de récupérer les infos serveur pour le moment",
              color: "warning",
              timeout: 4000,
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    async addAssociation(context, payload: string) {
      try {
        // We check if the association exists
        if (
          context.state.data.associations.filter((asso) => {
            return asso.name.toLowerCase() === payload.toLowerCase();
          }).length
        ) {
          context.dispatch("showAlert", {
            message: "Cette association est déjà enregistrée",
            color: "warning",
            timeout: 4000,
          });
        } else {
          const route = `${process.env.VUE_APP_API_HOST}/association`;
          const headers = new Headers();
          headers.set("Content-Type", "application/json");
          headers.set("Authorization", `Bearer ${context.state.admin.token}`);

          const response = await fetch(route, {
            method: "POST",
            headers,
            body: JSON.stringify({ name: payload }),
          });
          if (response.status === 201) {
            context.dispatch("showAlert", {
              message: "Association ajoutée à la base de données",
              color: "success",
              timeout: 4000,
            });
            await context.dispatch("refreshAssociations");
          } else {
            context.dispatch("showAlert", {
              message: "Impossible d'ajouter l'association",
              color: "error",
              timeout: 4000,
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async deleteAssociation(context, payload: number) {
      try {
        const route = `${process.env.VUE_APP_API_HOST}/association/${payload}`;
        const headers = new Headers();
        headers.set("Authorization", `Bearer ${context.state.admin.token}`);

        const response = await fetch(route, {
          method: "DELETE",
          headers,
        });
        if (response.status === 200) {
          context.dispatch("showAlert", {
            message: "L'association a été supprimée",
            color: "success",
            timeout: 4000,
          });
          await context.dispatch("refreshAssociations");
        } else {
          context.dispatch("showAlert", {
            message: "Impossible de supprimer l'association",
            color: "error",
            timeout: 4000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async refreshPostcodes(context) {
      try {
        const route = `${process.env.VUE_APP_API_HOST}/postcode`;
        const response = await fetch(route, {
          method: "GET",
        });
        if (!(response.status === 200)) {
          context.dispatch("showAlert", {
            message:
              "Le serveur n'est pas en mesure de traiter les demandes actuellement",
            color: "error",
            timeout: 3000,
          });
        } else {
          const postcodes = await response.json();
          context.commit("POSTCODES", postcodes);
        }
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Le serveur n'est pas en mesure de traiter les demandes actuellement",
          color: "error",
          timeout: 3000,
        });
      }
    },
    async refreshCities(context) {
      try {
        const route = `${process.env.VUE_APP_API_HOST}/city`;
        const response = await fetch(route, {
          method: "GET",
        });
        if (!(response.status === 200)) {
          context.dispatch("showAlert", {
            message:
              "Le serveur n'est pas en mesure de traiter les demandes actuellement",
            color: "error",
            timeout: 3000,
          });
        } else {
          const cities = await response.json();
          context.commit("CITIES", cities);
        }
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Le serveur n'est pas en mesure de traiter les demandes actuellement",
          color: "error",
          timeout: 3000,
        });
      }
    },
    async addCity(context, payload: { postcode: Postcode; cityName: string }) {
      try {
        const headers = new Headers();
        headers.set("Content-Type", "application/json");
        headers.set("Authorization", `Bearer ${context.state.admin.token}`);
        let cityId = 0;
        //First of all, we check if the city exists, if it doesn't, we create it and get the ID from the server
        const city = context.state.data.cities.find((value) => {
          return value.name.toLowerCase() === payload.cityName.toLowerCase();
        });
        if (!city) {
          const route = `${process.env.VUE_APP_API_HOST}/city`;
          const response = await fetch(route, {
            method: "POST",
            headers,
            body: JSON.stringify({ name: payload.cityName }),
          });
          if (!(response.status === 201)) {
            throw "error";
          } else {
            const responseObject = await response.json();
            cityId = responseObject.id;
          }
        } else {
          cityId = city.id;
        }
        // We then add the city to the postcode
        const route = `${process.env.VUE_APP_API_HOST}/postcode/${payload.postcode.id}/city/${cityId}`;
        const response = await fetch(route, {
          method: "PUT",
          headers,
        });
        if (response.status === 200) {
          context.dispatch("showAlert", {
            message: "La commune a correctement été ajoutée",
            color: "success",
            timeout: 4000,
          });
          await context.dispatch("refreshPostcodes");
        } else {
          throw "error";
        }
      } catch (error) {
        context.dispatch("showAlert", {
          message: "Un problème est apparu lors de la création de la commune",
          color: "error",
          timeout: 3000,
        });
        console.error(error);
      }
    },
    async removeCity(context, payload: { postcode: Postcode; cityId: number }) {
      try {
        const route = `${process.env.VUE_APP_API_HOST}/postcode/${payload.postcode.id}/city/${payload.cityId}`;
        const headers = new Headers();
        headers.set("Authorization", `Bearer ${context.state.admin.token}`);
        const response = await fetch(route, {
          headers,
          method: "DELETE",
        });
        if (!(response.status === 200)) {
          throw "error";
        } else {
          context.dispatch("showAlert", {
            message: "La commune a été retirée",
            color: "success",
            timeout: 3000,
          });
          await context.dispatch("refreshPostcodes");
        }
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Un problème est apparu lors de la suppression de la commune",
          color: "error",
          timeout: 3000,
        });
        console.error(error);
      }
    },
    async addPostcode(context, payload: string) {
      try {
        const route = `${process.env.VUE_APP_API_HOST}/postcode`;
        const headers = new Headers();
        headers.set("Content-Type", "application/json");
        headers.set("Authorization", `Bearer ${context.state.admin.token}`);

        // We try to find and existing postcode, if found, error message
        const existingPostcode = context.state.data.postcodes.find((value) => {
          return value.value === payload;
        });
        if (existingPostcode) {
          context.dispatch("showAlert", {
            message: "Ce code postal existe déjà",
            color: "warning",
            timeout: 4000,
          });
          throw false;
        }

        const response = await fetch(route, {
          method: "POST",
          headers,
          body: JSON.stringify({ value: payload }),
        });
        if (!(response.status === 201)) {
          throw "error";
        }
        //If we made it here, the postcode has been added, we can refresh the list
        context.dispatch("showAlert", {
          message: "Code postal ajouté",
          color: "success",
          timeout: 3000,
        });
        await context.dispatch("refreshPostcodes");
      } catch (error) {
        if (error) {
          context.dispatch("showAlert", {
            message:
              "Un problème est apparu lors de la création du code postal",
            color: "error",
            timeout: 3000,
          });
        }
      }
    },
    async deletePostcode(context, payload: number) {
      try {
        const route = `${process.env.VUE_APP_API_HOST}/postcode/${payload}`;
        const headers = new Headers();
        headers.set("Content-Type", "application/json");
        headers.set("Authorization", `Bearer ${context.state.admin.token}`);

        const response = await fetch(route, {
          headers,
          method: "DELETE",
        });
        if (!(response.status === 200)) {
          throw "error";
        }
        context.dispatch("showAlert", {
          message: "Code postal supprimé",
          color: "success",
          timeout: 3000,
        });
        await context.dispatch("refreshPostcodes");
      } catch (error) {
        context.dispatch("showAlert", {
          message:
            "Un problème est apparu lors de la suppression du code postal",
          color: "error",
          timeout: 3000,
        });
      }
    },
  },
  modules: {},
});
