
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  name: "Home",

  components: {},
  computed: {
    ...mapState(["data"]),
    active() {
      return this.data.config.isActive ? "Actif" : "En attente";
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions(["refreshConfig", "refreshCoupons"]),
  },
  async beforeMount() {
    await this.refreshConfig();
    await this.refreshCoupons();
    // Refresh config
    // Refresh coupons
  },
});
