import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";
import auth from "@/modules/auth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/coupons",
    name: "Coupons",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "coupon" */ "../views/Coupon.vue"),
  },
  {
    path: "/associations",
    name: "Associations",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "associations" */ "../views/Association.vue"),
  },
  {
    path: "/communes",
    name: "Communes",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "communes" */ "../views/CityPostcode.vue"),
  },
  {
    path: "/parametres",
    name: "Parametres",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "config" */ "../views/Config.vue"),
  },
  {
    path: "/admins",
    name: "Admins",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "admins" */ "../views/Admin.vue"),
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Navigation guards
router.beforeEach(async (to, from, next) => {
  // Before each route, we need to check if our token is still valid
  if (store.state.admin.isLogged) {
    if (!(await auth.tokenStillValid(store.state.admin.token))) {
      auth.logout();
      next("/login");
    }
  } else {
    const localAdminInfos = auth.parseLocalStorage();
    if (localAdminInfos) {
      if (auth.tokenStillValid(localAdminInfos.token)) {
        auth.storeLogin(localAdminInfos.token, localAdminInfos.userId);
      }
    }
  }

  // If we don't go to the login page, we need to check if we're logged, if not, go to login :
  if (to.path !== "/login") {
    if (!store.state.admin.isLogged) {
      next("/login");
    } else {
      next();
    }
  } else {
    auth.logout();
    next();
  }
});

export default router;
