
import Vue from "vue";
import Header from "@/components/Header.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import { mapState } from "vuex";

export default Vue.extend({
  components: {
    Header,
    NavDrawer,
  },
  name: "App",
  computed: {
    ...mapState(["admin", "alert"]),
  },
});
