
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    menu: {
      top: [
        { name: "Dashboard", icon: "mdi-view-dashboard", link: "/" },
        { name: "Coupons", icon: "mdi-ticket-account", link: "/coupons" },
        {
          name: "Associations",
          icon: "mdi-account-group",
          link: "/associations",
        },
        { name: "Communes", icon: "mdi-city", link: "/communes" },
      ],
      bottom: [
        { name: "Paramètres", icon: "mdi-cog", link: "/parametres" },
        {
          name: "Administrateurs",
          icon: "mdi-shield-account",
          link: "/admins",
        },
      ],
    },
  }),
});
