import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import fr from "vuetify/src/locale/fr";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { fr },
    current: "fr",
  },
  theme: {
    themes: {
      light: {
        primary: "#f4a733",
        secondary: "#485f6d",
        accent: "#bccf00",
        info: "#44bdf0",
        anchor: "#44bdf0",
      },
    },
  },
});
